import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "My Second Post!",
  "date": "2015-05-06T23:46:37.121Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Wow! I love blogging so much already.`}</p>
    <p>{`Did you know that "despite its name, salted duck eggs can also be made from
chicken eggs, though the taste and texture will be somewhat different, and the
egg yolk will be less rich."?
(`}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Salted_duck_egg"
      }}>{`Wikipedia Link`}</a>{`)`}</p>
    <p>{`Yeah, I didn't either.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      